import { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

const NotificationContext = createContext({
  trigger: () => {},
  save: () => {},
  list: [],
});

export const useNotifications = () => useContext(NotificationContext);

export default function NotificationProvider(props) {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const _notifications = JSON.parse(localStorage.getItem("notifications"));
    setNotifications(_notifications || []);
  }, []);

  const trigger = (type, text, subtext) => {
    let _notifications = notifications || [];
    _notifications = [{ id: uuid(), type, text, subtext }, ...notifications];
    localStorage.setItem("notifications", JSON.stringify(_notifications));
    setNotifications(_notifications);
    toast(text, { type });
  };

  const save = (type, text, subtext) => {
    let _notifications = notifications || [];
    _notifications = [{ id: uuid(), type, text, subtext }, ...notifications];
    localStorage.setItem("notifications", JSON.stringify(_notifications));
    setNotifications(_notifications);
  };

  return (
    <NotificationContext.Provider value={{ trigger, save, list: notifications }}>
      {props.children}
    </NotificationContext.Provider>
  );
}
